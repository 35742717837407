import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Verified = () => (
  <Layout>
    <SEO title="Verified" />
    <div className="text-center page-404 d-flex flex-column align-items-center justify-content-center">
      <h1>Thank you for verifying your identity, you can login now using mobile app</h1>
   </div>
  </Layout>
)

export default Verified